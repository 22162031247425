<template>
   <v-sheet height="100vh" color="transparent"  >

       <v-row class="no-gutters flex-wrap flex-column fill-height " >

         <!-- Business/Fine Logo-->
         <v-col cols="auto" class="shrink"  >
           <v-sheet color="transparent" height="60"  class="d-flex   align-center" :class="sideMenu ? 'justify-space-between' : 'justify-center'" >
             <div style="width: 100%" v-if="sideMenu" >
               <router-link :to="`/${ $store.state.domain ? '' : $store.state.business.selectedBusiness.alias}`" target="_blank">
                 <div >
                   <img id="dashboard_logo_fine" v-if="$store.state.project === 'fine'   "  height="45px" class="mt-3 pl-6" alt="WeStudy" src="@/assets/brand/logo_fine.png" />
                   <img id="dashboard_logo"      v-if="$store.state.project === 'westudy'"  height="25px" class="mt-3 pl-6" alt="WeStudy" src="/library/img/westudy_logo.png" />
                 </div>
                 <div>

                 </div>
               </router-link>
             </div>

             <div class="">
               <v-btn :color="wsACCENT"
                      :class="[{'mr-5' : sideMenu}]"
                      @click="$store.state.dashboard.sideMenu = !$store.state.dashboard.sideMenu"  icon  >
                 <v-icon  >mdi-arrow-collapse-{{ sideMenu ? 'left' : 'right' }}</v-icon>
               </v-btn>
             </div>
             <!-- User Name and Email-->

           </v-sheet>
         </v-col>

         <v-col cols="auto" class="grow overflow-y-auto " style="height: 100px"  >
           <menuElementsBusiness />
         </v-col>

       </v-row>

  </v-sheet>

</template>

<script>
import {mapState} from 'vuex';

import menuElementsBusiness from "@/components/pages/businessDashboard/UI/sidebarBusiness/menuElementsBusiness";
export default {
  name: "sideBarBusiness",
  components : {
    menuElementsBusiness
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('dashboard',['sideMenu']),

    displayCondition() {
      if ( this.displayShow(['sm'])) {
       return this.sideMenu
      }
      return true
    },
    
    menuStyleClass() {
      if ( this.sideMenu ) {
        return 'menuActive'
      }
      return ''
    }
  }


}
</script>

<style scoped>

.menu_header {
  height: 70px;
}

.menu {

  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: all 0.3s ease-out;
  overflow-y: auto;
}
.menuActiveMobile {
  position: fixed;
  display: block;
  z-index: 999999;
  width: 100%;
  transition: all 0.3s ease-out;
}
.menuActive {
  width:280px !important;
  transition: all 0.3s ease-out;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>